@import "./_sass-essentials/essentials.scss";

.pt--tw-layout-col {
  > .paragraph-inner {
    width: 100%;
  }

  &.vertical-align-center {
    display: flex;
    align-items: center;
  }

  @include mq(false, $bp-xs-max) {
    &.vertical-align-center {
      text-align: center;
    }

    &.has-mobile-col-bg {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin-left: $l-gutter-half;
      margin-right: $l-gutter-half;
      flex-basis: calc(100% - #{$l-gutter});
      max-width: calc(100% - #{$l-gutter});
      padding-top: 50px;
      padding-bottom: 50px;

      .pt--tw-layout-row .grid-cols-wrap > &,
      .pt--tw-layout-row .grid-cols-wrap > &:last-child {
        margin-bottom: -20px;
      }
    }
  }

  @include mq($bp-sm-min) {
    &.has-mobile-col-bg {
      background-image: none !important;
    }
  }
}
