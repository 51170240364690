.pt--tw-layout-col > .paragraph-inner {
  width: 100%;
}

.pt--tw-layout-col.vertical-align-center {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .pt--tw-layout-col.vertical-align-center {
    text-align: center;
  }
  .pt--tw-layout-col.has-mobile-col-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 15px;
    margin-right: 15px;
    flex-basis: calc(100% - 30px);
    max-width: calc(100% - 30px);
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pt--tw-layout-row .grid-cols-wrap > .pt--tw-layout-col.has-mobile-col-bg,
  .pt--tw-layout-row .grid-cols-wrap > .pt--tw-layout-col.has-mobile-col-bg:last-child {
    margin-bottom: -20px;
  }
}

@media screen and (min-width: 768px) {
  .pt--tw-layout-col.has-mobile-col-bg {
    background-image: none !important;
  }
}
